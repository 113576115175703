import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
// import './debug.css'
import { withTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

const TemplateWrapper = class extends React.Component {

  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div >
        <Helmet>
          <html lang={this.props.i18n.language} className="has-navbar-fixed-top"/>
          <title>{this.props.t('title')}</title>
          <meta name="description" content={this.props.t('description')} />
          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={this.props.t('title')}/>
          <meta property="og:url" content="/" />
          {/* <meta property="og:image" content="/img/og-image.jpg" /> */}

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-16x16.png"
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href="/img/safari-pinned-tab.svg"
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />


        </Helmet>
        <Navbar />
        <div>{this.props.children}</div>
        <Footer />
        <CookieConsent
          location="top"
          buttonText={this.props.t('accept')}
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ background: "#16A8BB", opacity: 0.95}}
          buttonStyle={{color: "#ffffff",  background: "#16A8BB", border: "20", borderRadius: "5px",   padding: "10px 20px", fontSize: "20px" }}
          >
          <div className="container is-size-4">
            {this.props.t('terms')}
          </div>
        </CookieConsent>
      </div>
    )
  }
}

export default withTranslation()(TemplateWrapper);

