import React from 'react';
import { withTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

const LanguageSwitcher = class extends React.Component {

  render() {
    return (
      <div>
        <a className="button is-white" onClick={() => {
          this.props.i18n.changeLanguage('de');
          navigate("/de");
          }}>de</a>
        <a className="button is-white" onClick={() => {
          this.props.i18n.changeLanguage('en');
          navigate("/en");
        }}>en</a>
      </div>
    );
  }
}

export default withTranslation()(LanguageSwitcher);