import React from "react";
import { Link } from "gatsby";
import logo from "../img/x.svg";
import ms from "../img/ms.svg";
import { withTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {

        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand has-text-centered">
            <Link to={this.props.t('home_link')} className="navbar-item" title="Logo">
                <img src={logo} alt="Mallorca" style={{ margin: "0" }} />
              </Link>
              <Link to={this.props.t('home_link')} className="navbar-item" title="Logo">
                <img src={ms} alt="Mallorca" style={{ margin: "0" }} />
              </Link>
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}>
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu"  className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to={this.props.t('beach_link')}>
                {this.props.t('beach')}
              </Link>
              <Link className="navbar-item" to={this.props.t('places_link')}>
                {this.props.t('places')}
              </Link>
              <Link className="navbar-item" to={this.props.t('activities_link')}>
                {this.props.t('activities')}
              </Link>
              <Link className="navbar-item" to={this.props.t('food_link')}>
                {this.props.t('food')}
              </Link>
            </div>
            <div >
              <LanguageSwitcher className="navbar-item" />
            </div>
          </div>
        </div>
      </nav>
    );
  }
};
export default withTranslation()(Navbar);
